<template>
    <div id="app">
        <Header :page="page" @modal="modal = $event"></Header>
        <transition name="fade" mode="out-in">
            <div key="page" v-if="page" class="container page-container">
                <router-view @modal="modal = $event"></router-view>
            </div>
            <div key="loader" v-else class="page-loader"></div>
        </transition>
        <Footer></Footer>
        <Modal :open-modal="modal" @close="modal = $event" />
    </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import Modal from "@/components/Modal";
export default {
    name: "App",
    components: {
        Footer,
        Header,
        Modal,
    },
    data() {
        return {
            page: null,
            modal: false,
        };
    },
    mounted() {
        //const url = "/mock.json";
        this.$axios.get("/api/v1/parking/landing").then(({ data }) => {
            const res = data.data;
            this.page = res;
        });
    },
};
</script>

<style lang="less">
@import url("./assets/less/vars.less");
@import url("./assets/less/fonts.less");
@import url("./assets/less/style.less");
</style>
