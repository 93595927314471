<template>
    <div class="header flex-c" :class="{ is_menu_open: isShowMenu }">
        <div class="container">
            <div class="header__content flex-c">
                <a href="/">
                    <svg
                        class="header__logo"
                        width="237"
                        height="64"
                        viewBox="0 0 237 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.9207 31.1285C15.3869 31.1285 15.842 31.1173 16.286 31.095C16.73 31.0503 17.1075 30.8603 17.4183 30.5251C17.8179 30.0782 18.0177 29.3966 18.0177 28.4804C18.0177 28.1676 17.9844 27.8436 17.9178 27.5084C17.8512 27.1732 17.6625 26.8827 17.3517 26.6369C17.0409 26.3687 16.6745 26.2123 16.2527 26.1676C15.8531 26.1229 15.4313 26.1006 14.9873 26.1006H10.8247C10.5139 26.1006 10.2697 26.1564 10.0921 26.2682C9.93669 26.3575 9.81458 26.4693 9.72578 26.6034C9.63698 26.7374 9.58148 26.8827 9.55928 27.0391C9.53708 27.1955 9.52598 27.3408 9.52598 27.4749V31.1285H14.9207ZM2 43.7318V24.6927C2 24.2011 2.0555 23.6536 2.1665 23.0503C2.27751 22.4469 2.53281 21.9218 2.93242 21.4749C3.17663 21.1844 3.55404 20.9162 4.06465 20.6704C4.57526 20.4022 5.23018 20.2682 6.02939 20.2682H18.9168C19.8492 20.2682 20.7372 20.3687 21.5809 20.5698C22.4245 20.7486 23.1682 21.1955 23.812 21.9106C24.367 22.4916 24.7888 23.2849 25.0774 24.2905C25.3883 25.2737 25.5437 26.6145 25.5437 28.3128C25.5437 28.7151 25.5215 29.2514 25.4771 29.9218C25.4549 30.5698 25.366 31.2514 25.2106 31.9665C25.0774 32.6816 24.8665 33.3743 24.5779 34.0447C24.3115 34.7151 23.9341 35.2626 23.4457 35.6872C22.8907 36.1564 22.2247 36.4916 21.4477 36.6927C20.6928 36.8715 19.9935 36.9609 19.3497 36.9609H9.52598V43.7318H2Z"
                            fill="#E60000"
                        />
                        <path
                            d="M36.7684 33.5419H41.5637L39.4657 27.6089C39.4213 27.5196 39.3658 27.419 39.2992 27.3073C39.2548 27.1732 39.2104 27.1061 39.166 27.1061C39.1216 27.1061 39.0661 27.1732 38.9995 27.3073C38.9551 27.419 38.9107 27.5196 38.8663 27.6089L36.7684 33.5419ZM33.8379 22.8492C34.0155 22.4246 34.2264 22.0335 34.4706 21.676C34.7148 21.3184 35.0367 21.0168 35.4363 20.7709C35.8359 20.5251 36.3244 20.3352 36.9016 20.2011C37.501 20.067 38.2558 20 39.166 20C39.721 20 40.2538 20.0335 40.7645 20.1006C41.2973 20.1453 41.7857 20.2682 42.2297 20.4693C42.6959 20.648 43.1177 20.9274 43.4951 21.3073C43.8947 21.6872 44.2277 22.2011 44.4941 22.8492L53.119 43.7318H45.1935L43.3619 38.905H34.9701L33.1386 43.7318H25.213L33.8379 22.8492Z"
                            fill="#E60000"
                        />
                        <path
                            d="M54.7948 24.7598C54.7948 24.2235 54.8391 23.6536 54.928 23.0503C55.0168 22.4246 55.2721 21.8771 55.6939 21.4078C56.1379 20.9162 56.6596 20.6034 57.259 20.4693C57.8584 20.3352 58.3801 20.2682 58.8241 20.2682H71.5117C71.867 20.2682 72.2888 20.2793 72.7772 20.3017C73.2656 20.324 73.7651 20.4134 74.2757 20.5698C74.7863 20.7039 75.2858 20.9274 75.7742 21.2402C76.2627 21.5531 76.6956 21.9888 77.073 22.5475C77.5836 23.3073 77.9055 24.1676 78.0387 25.1285C78.1941 26.0894 78.2718 27.0168 78.2718 27.9106C78.2718 28.1788 78.2607 28.5475 78.2385 29.0168C78.2385 29.4637 78.2052 29.9553 78.1386 30.4916C78.072 31.0279 77.9832 31.5754 77.8722 32.1341C77.7612 32.6927 77.6058 33.2067 77.406 33.676C76.962 34.7263 76.3515 35.4749 75.5744 35.9218C74.8196 36.3687 73.9871 36.5922 73.0769 36.5922H72.0779V36.7263L78.738 43.7318H69.6802L65.1846 37.6313C64.8072 37.1173 64.4409 36.6034 64.0857 36.0894C63.7527 35.5754 63.5862 35.0279 63.5862 34.4469V33.9441C63.5862 33.162 63.897 32.5251 64.5186 32.0335C65.1624 31.5419 65.9727 31.2961 66.9495 31.2961H68.1484C68.4814 31.2961 68.8477 31.2626 69.2473 31.1955C69.6469 31.1061 69.9799 30.905 70.2463 30.5922C70.4239 30.3464 70.546 30.0447 70.6126 29.6872C70.7014 29.3073 70.7458 28.9609 70.7458 28.648C70.7458 28 70.6681 27.5307 70.5127 27.2402C70.3795 26.9497 70.2685 26.7598 70.1797 26.6704C69.8911 26.3575 69.5581 26.1899 69.1807 26.1676C68.8033 26.1229 68.437 26.1006 68.0818 26.1006H63.9192C63.6972 26.1006 63.4863 26.1229 63.2864 26.1676C63.1088 26.1899 62.9312 26.2905 62.7536 26.4693C62.5094 26.6927 62.3762 26.9274 62.354 27.1732C62.3318 27.3966 62.3207 27.6536 62.3207 27.9441V43.7318H54.7948V24.7598Z"
                            fill="#E60000"
                        />
                        <path
                            d="M82.0702 20.2682H89.5962V43.7318H82.0702V20.2682ZM98.7539 43.7318L91.6941 34.648C91.0059 33.7765 90.6618 32.7933 90.6618 31.6983C90.6618 31.2514 90.7284 30.7933 90.8616 30.324C90.9948 29.8324 91.1724 29.4302 91.3944 29.1173L97.6549 20.2682H106.58L98.4875 29.9553C98.3099 30.1564 98.1434 30.4134 97.988 30.7263C97.8548 31.0168 97.7881 31.3184 97.7881 31.6313C97.7881 31.8771 97.8437 32.1899 97.9547 32.5698C98.0657 32.9497 98.3321 33.3631 98.7539 33.8101L107.812 43.7318H98.7539Z"
                            fill="#E60000"
                        />
                        <path
                            d="M109.476 43.7318V20.2682H117.002V43.7318H109.476Z"
                            fill="#E60000"
                        />
                        <path
                            d="M146.778 39.8101C146.778 40.0112 146.745 40.3464 146.678 40.8156C146.612 41.2849 146.412 41.7542 146.079 42.2235C145.746 42.6927 145.235 43.1061 144.547 43.4637C143.881 43.8212 142.938 44 141.717 44C141.273 44 140.829 43.9777 140.385 43.933C139.941 43.8883 139.497 43.7877 139.053 43.6313C138.631 43.4749 138.209 43.2402 137.787 42.9274C137.387 42.6145 137.01 42.2011 136.655 41.6872L128.23 29.7207C128.141 29.6089 128.041 29.486 127.93 29.352C127.819 29.2179 127.708 29.1508 127.597 29.1508C127.553 29.1508 127.464 29.1844 127.331 29.2514C127.197 29.2961 127.131 29.486 127.131 29.8212V43.7318H121.336V24.1564C121.336 23.3966 121.481 22.7598 121.769 22.2458C122.058 21.7095 122.447 21.2737 122.935 20.9385C123.423 20.6034 123.978 20.3687 124.6 20.2346C125.244 20.0782 125.899 20 126.565 20C126.876 20 127.242 20.0223 127.664 20.067C128.085 20.0894 128.518 20.1788 128.962 20.3352C129.406 20.4693 129.85 20.6816 130.294 20.9721C130.738 21.2626 131.138 21.6648 131.493 22.1788L139.985 34.2458C140.074 34.3575 140.163 34.4804 140.251 34.6145C140.362 34.7486 140.484 34.8156 140.618 34.8156C140.795 34.8156 140.895 34.7486 140.917 34.6145C140.962 34.4581 140.984 34.3352 140.984 34.2458V20.2682H146.778V39.8101Z"
                            fill="#E60000"
                        />
                        <path
                            d="M173.761 40.2123C173.761 40.9944 173.616 41.5978 173.328 42.0223C173.061 42.4469 172.828 42.7486 172.628 42.9274C171.984 43.4637 171.152 43.7318 170.131 43.7318H159.108C158.154 43.7318 157.121 43.5754 156.011 43.2626C154.923 42.9274 154.002 42.4246 153.247 41.7542C152.603 41.1955 152.093 40.5251 151.715 39.743C151.36 38.9385 151.083 38.1117 150.883 37.2626C150.705 36.3911 150.583 35.5196 150.517 34.648C150.472 33.7542 150.45 32.9162 150.45 32.1341C150.45 31.1955 150.483 30.2458 150.55 29.2849C150.616 28.3017 150.761 27.352 150.983 26.4358C151.205 25.5196 151.538 24.6704 151.982 23.8883C152.426 23.1061 153.025 22.4358 153.78 21.8771C154.313 21.4749 154.89 21.1732 155.512 20.9721C156.133 20.7486 156.722 20.5922 157.277 20.5028C157.832 20.3911 158.32 20.324 158.742 20.3017C159.186 20.2793 159.486 20.2682 159.641 20.2682H173.228V25.8324H163.038C161.173 25.8324 159.83 26.4804 159.008 27.7765C158.609 28.4246 158.364 29.1285 158.276 29.8883C158.209 30.648 158.176 31.352 158.176 32C158.176 32.648 158.209 33.352 158.276 34.1117C158.364 34.8715 158.62 35.5978 159.042 36.2905C159.463 36.9385 159.952 37.3743 160.507 37.5978C161.062 37.7989 161.661 37.8994 162.305 37.8994H164.569C164.769 37.8994 164.98 37.8883 165.202 37.8659C165.446 37.8212 165.657 37.7095 165.835 37.5307C166.013 37.352 166.124 37.1397 166.168 36.8939C166.212 36.648 166.235 36.4022 166.235 36.1564V31.3296H173.761V40.2123Z"
                            fill="#E60000"
                        />
                        <path
                            d="M185.057 43.7318C184.436 43.7318 183.914 43.6089 183.492 43.3631C183.07 43.1173 182.726 42.7933 182.46 42.3911C182.216 41.9665 182.038 41.4972 181.927 40.9832C181.816 40.4693 181.76 39.9441 181.76 39.4078C181.76 39.1844 181.772 38.8715 181.794 38.4693C181.838 38.0447 181.927 37.6089 182.06 37.162C182.193 36.7151 182.393 36.2905 182.66 35.8883C182.926 35.4637 183.303 35.1173 183.792 34.8492L193.616 29.6201C194.126 29.352 194.448 29.0279 194.581 28.648C194.715 28.2458 194.781 27.8994 194.781 27.6089C194.781 27.4302 194.759 27.2291 194.715 27.0056C194.67 26.7821 194.559 26.581 194.381 26.4022C194.115 26.1341 193.793 25.9888 193.416 25.9665C193.038 25.9441 192.705 25.933 192.417 25.933H183.126V20.2682H197.145C197.789 20.2682 198.477 20.4022 199.21 20.6704C199.943 20.9162 200.542 21.2961 201.008 21.8101C201.586 22.4358 201.941 23.1955 202.074 24.0894C202.229 24.9609 202.307 25.8101 202.307 26.6369C202.307 27.7989 202.218 28.7821 202.041 29.5866C201.885 30.3687 201.652 31.0279 201.341 31.5642C201.053 32.1006 200.686 32.5475 200.242 32.905C199.821 33.2402 199.332 33.5419 198.777 33.8101L191.717 37.3296C191.651 37.3743 191.573 37.419 191.484 37.4637C191.418 37.5084 191.384 37.5754 191.384 37.6648C191.384 37.7542 191.407 37.7989 191.451 37.7989C191.518 37.7989 191.573 37.7989 191.618 37.7989H201.874V43.7318H185.057Z"
                            fill="white"
                        />
                        <path
                            d="M218.25 28.514C218.25 28.4246 218.206 28.3799 218.117 28.3799C218.028 28.3799 217.95 28.4246 217.884 28.514C217.839 28.581 217.773 28.648 217.684 28.7151L212.056 33.743C212.012 33.7877 211.956 33.8436 211.89 33.9106C211.845 33.9777 211.823 34.0335 211.823 34.0782C211.823 34.1453 211.845 34.2123 211.89 34.2793C211.956 34.3464 212.078 34.3799 212.256 34.3799H218.25V28.514ZM218.25 40.5475H207.794C207.416 40.5475 207.017 40.4916 206.595 40.3799C206.195 40.2682 205.818 39.9553 205.463 39.4413C205.44 39.419 205.385 39.3408 205.296 39.2067C205.207 39.0503 205.107 38.8268 204.996 38.5363C204.908 38.2235 204.819 37.8436 204.73 37.3966C204.663 36.9497 204.63 36.4246 204.63 35.8212C204.63 35.1285 204.674 34.5363 204.763 34.0447C204.874 33.5531 205.007 33.1285 205.163 32.7709C205.34 32.4134 205.529 32.1117 205.729 31.8659C205.951 31.5978 206.184 31.352 206.428 31.1285L217.051 21.5084C217.429 21.1732 217.961 20.838 218.65 20.5028C219.338 20.1676 220.304 20 221.547 20C222.124 20 222.723 20.067 223.345 20.2011C223.967 20.3352 224.5 20.6257 224.944 21.0726C225.388 21.5196 225.632 21.9665 225.676 22.4134C225.743 22.838 225.776 23.2961 225.776 23.7877V34.3799H229.239V40.5475H225.776V43.7318H218.25V40.5475Z"
                            fill="white"
                        />
                        <path
                            d="M185.057 43.7318C184.436 43.7318 183.914 43.6089 183.492 43.3631C183.07 43.1173 182.726 42.7933 182.46 42.3911C182.216 41.9665 182.038 41.4972 181.927 40.9832C181.816 40.4693 181.76 39.9441 181.76 39.4078C181.76 39.1844 181.772 38.8715 181.794 38.4693C181.838 38.0447 181.927 37.6089 182.06 37.162C182.193 36.7151 182.393 36.2905 182.66 35.8883C182.926 35.4637 183.303 35.1173 183.792 34.8492L193.616 29.6201C194.126 29.352 194.448 29.0279 194.581 28.648C194.715 28.2458 194.781 27.8994 194.781 27.6089C194.781 27.4302 194.759 27.2291 194.715 27.0056C194.67 26.7821 194.559 26.581 194.381 26.4022C194.115 26.1341 193.793 25.9888 193.416 25.9665C193.038 25.9441 192.705 25.933 192.417 25.933H183.126V20.2682H197.145C197.789 20.2682 198.477 20.4022 199.21 20.6704C199.943 20.9162 200.542 21.2961 201.008 21.8101C201.586 22.4358 201.941 23.1955 202.074 24.0894C202.229 24.9609 202.307 25.8101 202.307 26.6369C202.307 27.7989 202.218 28.7821 202.041 29.5866C201.885 30.3687 201.652 31.0279 201.341 31.5642C201.053 32.1006 200.686 32.5475 200.242 32.905C199.821 33.2402 199.332 33.5419 198.777 33.8101L191.717 37.3296C191.651 37.3743 191.573 37.419 191.484 37.4637C191.418 37.5084 191.384 37.5754 191.384 37.6648C191.384 37.7542 191.407 37.7989 191.451 37.7989C191.518 37.7989 191.573 37.7989 191.618 37.7989H201.874V43.7318H185.057Z"
                            stroke="#E60000"
                            stroke-width="2"
                        />
                        <path
                            d="M218.25 28.514C218.25 28.4246 218.206 28.3799 218.117 28.3799C218.028 28.3799 217.95 28.4246 217.884 28.514C217.839 28.581 217.773 28.648 217.684 28.7151L212.056 33.743C212.012 33.7877 211.956 33.8436 211.89 33.9106C211.845 33.9777 211.823 34.0335 211.823 34.0782C211.823 34.1453 211.845 34.2123 211.89 34.2793C211.956 34.3464 212.078 34.3799 212.256 34.3799H218.25V28.514ZM218.25 40.5475H207.794C207.416 40.5475 207.017 40.4916 206.595 40.3799C206.195 40.2682 205.818 39.9553 205.463 39.4413C205.44 39.419 205.385 39.3408 205.296 39.2067C205.207 39.0503 205.107 38.8268 204.996 38.5363C204.908 38.2235 204.819 37.8436 204.73 37.3966C204.663 36.9497 204.63 36.4246 204.63 35.8212C204.63 35.1285 204.674 34.5363 204.763 34.0447C204.874 33.5531 205.007 33.1285 205.163 32.7709C205.34 32.4134 205.529 32.1117 205.729 31.8659C205.951 31.5978 206.184 31.352 206.428 31.1285L217.051 21.5084C217.429 21.1732 217.961 20.838 218.65 20.5028C219.338 20.1676 220.304 20 221.547 20C222.124 20 222.723 20.067 223.345 20.2011C223.967 20.3352 224.5 20.6257 224.944 21.0726C225.388 21.5196 225.632 21.9665 225.676 22.4134C225.743 22.838 225.776 23.2961 225.776 23.7877V34.3799H229.239V40.5475H225.776V43.7318H218.25V40.5475Z"
                            stroke="#E60000"
                            stroke-width="2"
                        />
                    </svg>
                </a>
                <transition>
                    <div v-if="page" class="header__right flex-c text_16_b">
                        <a :href="`tel:${page.phone_value}`" class="flex-c">
                            <div class="header__icon">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10.8041 13.1969C9.46642 11.8593 9.16438 10.5216 9.09624 9.98567C9.0772 9.83748 9.1282 9.68887 9.23421 9.5836L9.80405 9.09579C10.1979 8.75866 10.2688 8.17646 9.96733 7.75468L8.58847 5.82529C8.27858 5.39167 7.68307 5.2773 7.23459 5.56525L5.51874 6.66695C5.20069 6.87116 5.02092 7.23765 5.08206 7.61063C5.34215 9.19722 6.17066 12.0201 9.07522 14.9248C11.9869 17.8367 14.816 18.662 16.4008 18.9199C16.7677 18.9796 17.1292 18.8063 17.3354 18.497L18.4629 16.8057C18.7623 16.3566 18.6513 15.751 18.2121 15.4372L16.2459 14.0328C15.8239 13.7314 15.2414 13.8026 14.9045 14.1969L14.4175 14.7668C14.3122 14.8728 14.1636 14.9238 14.0154 14.9048C13.4794 14.8366 12.1418 14.5346 10.8041 13.1969Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                            <span>{{ page.phone_label }}</span>
                        </a>
                        <a :href="page.telegram_value" class="flex-c">
                            <div class="header__icon">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.83813 10.6054L18.8012 4.07987C19.5421 3.79608 20.1892 4.27149 19.9491 5.45928L19.9505 5.45782L17.2325 19.034C17.0311 19.9965 16.4916 20.2306 15.7369 19.7771L11.5978 16.5429L9.6014 18.582C9.38064 18.8161 9.19439 19.0135 8.76668 19.0135L9.06056 14.5476L16.7317 7.20001C17.0655 6.88843 16.6572 6.7129 16.217 7.02301L6.73715 13.3511L2.65049 11.9994C1.76335 11.701 1.74403 11.0589 2.83813 10.6054Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </div>
                            <span>{{ page.telegram_label }}</span></a
                        >
                        <router-link
                            to="/dokumenty/oferta"
                            class="header__document-link"
                            ><span>Документы</span>
                            <img src="../assets/img/folder.svg" alt="" />
                        </router-link>
                        <button class="button" @click="$emit('modal', true)">
                            {{ page.form_title }}
                        </button>
                        <div
                            class="header__menu flex-c"
                            @click="isShowMenu = !isShowMenu"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M7 5C7 6.10457 6.10457 7 5 7C3.89543 7 3 6.10457 3 5C3 3.89543 3.89543 3 5 3C6.10457 3 7 3.89543 7 5ZM14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5ZM19 7C20.1046 7 21 6.10457 21 5C21 3.89543 20.1046 3 19 3C17.8954 3 17 3.89543 17 5C17 6.10457 17.8954 7 19 7ZM7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM19 14C20.1046 14 21 13.1046 21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14ZM5 21C6.10457 21 7 20.1046 7 19C7 17.8954 6.10457 17 5 17C3.89543 17 3 17.8954 3 19C3 20.1046 3.89543 21 5 21ZM12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21ZM21 19C21 20.1046 20.1046 21 19 21C17.8954 21 17 20.1046 17 19C17 17.8954 17.8954 17 19 17C20.1046 17 21 17.8954 21 19Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                    </div>
                </transition>
            </div>
            <transition name="fade">
                <div class="header-menu" v-if="isShowMenu">
                    <div class="container">
                        <div class="header-menu__content">
                            <!-- <a
                            :href="info ? $vars.lkUrl : $vars.authUrl"
                            class="header__user button button--wide"
                            :class="info ? 'button--white color-text002' : 'button--orange'"
                            v-if="$device.isMobile"
                        >
                            <svg-icon name="user" width="24" height="24" />
                            <template v-if="info">
                                <span>{{ info.first_name }} {{ info.last_name ? info.last_name[0] : "" }}.</span>
                                <span class="color-orange" @click.prevent="logout()">Выход</span>
                            </template>
                            <span v-else>Войти</span>
                        </a> -->
                            <template v-if="menu">
                                <OpenBlock
                                    v-for="(block, index) in menu.menu"
                                    :key="index"
                                    :state="index === 0 ? mainState : 42"
                                    @resetState="mainState = 42"
                                >
                                    <template slot-scope="{ toggle }">
                                        <div class="open-block">
                                            <div
                                                class="open-block__label flex-c "
                                                @click="toggle($event)"
                                            >
                                                <img
                                                    :src="block.parent_img"
                                                    alt=""
                                                    width="24"
                                                    height="24"
                                                />
                                                {{ block.parent_name }}
                                                <div
                                                    class="open-block__state"
                                                ></div>
                                            </div>
                                            <div class="open-block__wrapper">
                                                <div
                                                    class="header-menu__grid text_14_m"
                                                >
                                                    <a
                                                        v-for="(link,
                                                        lindex) in block.children"
                                                        :key="lindex"
                                                        :href="
                                                            link.children_link
                                                        "
                                                        class="header-menu__link"
                                                        @click="
                                                            isShowMenu = false
                                                        "
                                                    >
                                                        <img
                                                            :src="
                                                                link.children_img
                                                            "
                                                            alt=""
                                                            width="48"
                                                            height="48"
                                                        />

                                                        {{ link.children_name }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </OpenBlock>
                                <div class="open-block open-block--gray">
                                    <a
                                        v-for="(link,
                                        indzdohni) in menu.buttons"
                                        :key="indzdohni"
                                        :href="link.button_link"
                                        target="_blank"
                                        class="header-menu__link mb-1"
                                        >{{ link.button_name }}</a
                                    >
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="fade">
                <div
                    class="header-menu-dimmer"
                    @click="isShowMenu = false"
                    v-if="isShowMenu"
                ></div>
            </transition>
        </div>
    </div>
</template>
<script>
import OpenBlock from "@/components/OpenBlock";

export default {
    name: "Header",
    components: {
        OpenBlock,
    },
    data() {
        return {
            isShowMenu: false,
            menu: null,
            mainState: 1,
        };
    },
    props: {
        page: {
            type: Object,
            default: null,
        },
    },

    methods: {
        showForm() {
            document.querySelector(".apply").scrollIntoView();
        },
    },
    async mounted() {
        //const url = "/menu.json";
        const { data } = await this.$axios.get("/api/v1/amo/menu");
        this.menu = data.data;
        console.log(this.menu);
    },
};
</script>
<style lang="less">
@import url("../assets/less/vars.less");
.header {
    display: flex;
    flex-direction: column;
    padding: 4px 0;
    position: sticky;
    position: -webkit-sticky;
    z-index: 1;
    background: @bg001;
    top: 0;
    @media @sm {
        height: 56px;
        padding: 0;
    }
    &.is_menu_open {
        @media @sm {
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            overflow-y: hidden;
        }
    }
    &__logo {
        @media @sm {
            width: 168px;
            height: 46px;
        }
    }
    &__content {
        height: 64px;
        svg {
            display: block;
        }
        @media @sm {
            height: 56px;
        }
    }
    &__right {
        margin-left: auto;
        gap: 30px;
        @media @sm {
            gap: 5px;
        }
        a {
            color: @text001;
            text-decoration: none;
            &[href^="tel"] {
                @media @sm {
                    .header__icon {
                        background: @red;
                        svg {
                            filter: invert(1);
                        }
                    }
                }
            }
            &:hover {
                color: @red;
            }
            span {
                @media @sm {
                    display: none;
                }
            }
        }
    }
    &__icon {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background: #fff;
        box-shadow: 0px 3px 9px #bbbed3;
        padding: 8px;
        margin-right: 10px;
        @media @sm {
            margin-right: 0;
            box-shadow: none;
        }
    }
    .button {
        @media @sm {
            display: none;
        }
    }
    &__menu {
        width: 48px;
        height: 48px;
        justify-content: center;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        color: @text002;
        .trs(all);
        @media @sm {
            width: 40px;
            height: 40px;
        }
        .is_menu_open & {
            background: @blue;
            color: #fff;
        }
    }
    &__document-link {
        img {
            display: none;
            @media @sm {
                display: block;
                width: 24px;
                height: 24px;
            }
        }
        span {
            @media @sm {
                display: none;
            }
        }
        @media @sm {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.mb-1 {
    margin-bottom: 4px;
}
.header-menu {
    @media @sm {
        background: none;
        overflow-y: auto;
        margin: 0 -20px;
    }
    .container {
        position: relative;
        z-index: 1;
    }
    &__part {
        gap: 8px;
        & + & {
            gap: 16px;
            margin-left: 80px;
        }
    }
    &__link {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        text-align: center;
        color: @text002;
        .radius(6px);
        img {
            margin-bottom: 8px;
        }
        &:hover {
            background: #f2f3f8;
            color: @accent;
        }
    }
    &__content {
        position: absolute;
        justify-content: center;
        right: 0;
        top: 4px;
        background: @bg;
        padding: 16px;
        overflow-y: auto;
        max-height: 85vh;
        .radius(16px);
        @media @sm {
            position: static;
            padding: 8px 0;
            margin: 0 -8px;
            background: none;
        }
        &:before {
            content: "";
            position: absolute;
            bottom: 100%;
            width: 16px;
            height: 6px;
            right: 28px;
            //background: url(../assets/img/svg/tail.svg) center bottom / contain no-repeat;
            @media @sm {
                display: none;
            }
        }
    }
    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 112px);
        gap: 4px;
        padding: 16px;
        @media @sm {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    .open-block {
        background: #fff;
        + .open-block {
            margin-top: 4px;
        }
        .radius(6px);
        &__label {
            font-size: 16px;
            font-weight: 500;
            color: @text002;
            padding: 16px;
            img {
                margin-right: 6px;
            }
        }
        &__state {
            margin-left: auto;
            width: 16px;
            height: 16px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            &:after,
            &:before {
                content: "";
                display: block;
                width: 11px;
                border: 1px @text003 solid;
                border-radius: 2px;
            }
            &:before {
                transform: rotate(90deg);
                position: absolute;
                margin: auto;
                opacity: 0;
                .trs(opacity);
            }
        }
        &.open-block--overflow,
        &.is_open {
            .open-block__state {
                &:before {
                    opacity: 1;
                }
            }
        }
        &__content {
            &:before {
                content: "";
                display: block;
                margin: 0 28px;
                height: 1px;
                background: @border;
            }
        }
    }
}
.header-menu-dimmer {
    flex-grow: 1;
    background: @primary;
    opacity: 0.2;
    @media @sm {
        opacity: 1;
        background: @bg;
    }
}
.open-block--gray {
    margin-top: 8px !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    a {
        background: @red !important;
        text-align: center;
        color: #fff;
        &:hover {
            background: #fff !important;
            color: @red !important;
        }
    }
}
</style>
