!<template>
    <div class="dokumenty">
        <router-link to="/" class="dokumenty__back-link text_19_m">
            <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7 1.5L2 6.5L7 11.5"
                    stroke="#9A9BA4"
                    stroke-width="1.5"
                />
            </svg>
            На главную</router-link
        >
        <div class="dokumenty__title text_76_b">Документы</div>
        <div class="dokumenty__content">
            <div v-if="currentDoc && !loading" class="dokumenty__left">
                <div class="dokumenty__left-title">{{currentDoc.short_title}}</div>
                <div v-html='currentDoc.body'></div>
            </div>
            <div v-else class="dokumenty__left">
                <div class="dokumenty__left-title">Документ не найден</div>
            </div>
            <div class="dokumenty__right">
                <router-link v-for="link in documents" :to="link.slug" :key="link.slug" class="dokumenty__right-link"
                    >{{link.short_title}}</router-link
                >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "dokumenty",
    data(){
        return {
            documents:[],
            loading:false
        }       
    },
    async created() {
        this.loading = true;
        const {data} = await this.$axios.get("/api/v1/parking/documents");
        this.documents = data.data;
        this.loading = false;      
    },
    computed:{
        currentDoc(){
            const doc = this.documents.find((item)=> item.slug === this.$route.params.name)
            return doc
        }
    }
};
</script>
<style lang="less">
@import url("../../assets/less/vars.less");
@import url("../../assets/less/fonts.less");
@import url("../../assets/less/style.less");
.dokumenty {
    margin-top: 40px;
    margin-bottom: 48px;
    &__back-link {
        display: flex;
        align-items: center;
        color: @gray001;
        text-decoration: none;
        padding: 10px 10px 10px 16px;
        background: #fff;
        width: max-content;
        border-radius: 6px;
        margin-bottom: 12px;
        font-size: 19px;
        line-height: 19px;
        gap: 8px;
        svg {
            transform: translateY(-2px);
        }
        @media @sm {
            margin-bottom: 20px;
        }
    }
    &__title {
        background: linear-gradient(90deg, #13141d 0%, #8c8e9d 37.94%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 40px;
        @media @sm {
            margin-bottom: 30px;
        }
    }
    &__content {
        display: flex;
        gap: 30px;
        @media @md {
            flex-direction: column;
            align-items: center;
        }
    }
    &__left {
        background: #fff;
        padding: 24px 44px;
        flex-grow: 1;
        border-radius: 24px;
        &-title {
            padding: 32px 0 12px;
            font-size: 32px;
            line-height: 38px;
            font-weight: 700;
            @media @sm {
                font-size: 20px;
                padding: 15px 0 12px;
                line-height: 24px;
            }
        }
        h1,h2,h3 {
            padding: 24px 0 12px;
            font-size: 24px;
            line-height: 33px;
            font-weight: 700;
            @media @sm {
                font-size: 18px;
                padding: 12px 0;
                line-height: 22px;
            }
        }
        h2{
            font-size: 20px;
            line-height: 28px;
        }
        h3{
        font-size: 18px;
        line-height: 22px;
        }
        p {
            padding: 12px 0;
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            color: #4d4e54;
            
        }
        .bank-card {
                padding-left: 24px;
                li {
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: -24px;
                        background: #ff8000;
                        display: block;
                        width: 14px;
                        height: 2px;
                    }
                }
            }
        @media @md {
            width: 100%;
        }
        @media @sm {
            padding: 24px;
        }
    }
    &__right {
        width: 317px;
        min-width: 317px;
        border-radius: 24px;
        background: @dark;
        display: flex;
        flex-direction: column;
        padding: 8px 32px;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        height: max-content;
        .router-link-active {
            color: #ffc700;
        }
        &-link {
            color: #fff;
            text-decoration: none;
            padding: 12px 0;
            border-bottom: 1px solid #333643;
            &:last-child {
                border-bottom: none;
            }
        }
        @media screen and (max-width: 400px) {
            width: 100%;
            min-width: auto;
        }
    }
}
</style>
