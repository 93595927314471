import Vue from "vue";
import Router from "vue-router";
import MainPAge from "../pages/MainPage.vue";
import Dokument from "../pages/dokumenty/_.vue";
import Success from "../pages/Success.vue";

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: "/",
            name: "main",
            component: MainPAge,
        },
        {
            path: "/dokumenty/:name",
            component: Dokument,
        },
        {
            path: "/success",
            component: Success,
        },
    ],
    scrollBehavior() {
        // always scroll to top
        return { x: 0, y: 0 };
    },
});

export default router;
