<template>
    <div class="footer">
        <div class="footer-flex flex">
            <a href="https://company.arlan-auto.ru/" target="_blank">
                <img
                    src="/img/company.svg"
                    alt=""
                    width="24"
                    height="24"
                    srcset=""
                />
                Компания
            </a>
            <a href="https://arlan-auto.ru/" target="_blank">
                <img
                    src="/img/h_zapchasti.svg"
                    alt=""
                    width="24"
                    height="24"
                    srcset=""
                />
                Запчасти
            </a>
            <a href="https://a-a63.ru/" target="_blank">
                <img
                    src="/img/h_avtosalon.svg"
                    alt=""
                    width="24"
                    height="24"
                    srcset=""
                />
                Автосалон
            </a>
            <a href="https://aasto.ru/" target="_blank">
                <img
                    src="/img/h_avtoservis.svg"
                    alt=""
                    width="24"
                    height="24"
                    srcset=""
                />
                Автосервис
            </a>
        </div>
        <div class="footer">
            <span class="color-text3">© Parking 24, {{ date }}</span>
            <a href="https://parking.arlan-auto.ru/#/dokumenty/soglashenie" target="_blank"
                >Пользовательское соглашение</a
            >
        </div>
    </div>
</template>
<script>
export default {
    name: "Footer",
    data() {
        return {
            date: new Date().getFullYear(),
        };
    },
};
</script>
<style lang="less">
@import url("../assets/less/vars.less");
.footer {
    padding: 25px;
    text-align: center;
    margin-top: auto;
    color: @text002;

    @media @sm {
        display: grid;
        gap: 10px;
        padding: 24px;
        font-size: 14px;
        .color-text3 {
            color: @text002;
        }
    }
    > a,
    > span {
        opacity: 0.4;
    }
    > a {
        margin-left: 16px;
        text-decoration: none;
        opacity: 0.4;
        color: @text002;
        &:hover {
            color: @red;
            opacity: 1;
        }
        @media @sm {
            margin-left: 0;
        }
    }
}
.footer-flex {
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
    margin: 20px 0;
    a {
        display: flex;
        gap: 10px;
        text-decoration: none;
        color: @text002;
        align-items: center;
    }
}
.color-text3 {
    color: @text003;
}
</style>
