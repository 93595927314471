<template>
    <transition name="fade" mode="out-in">
        <div v-if="openModal" class="modal-request">
            <div
                v-click-outside="
                    () => {
                        $emit('close', false);
                    }
                "
                class="modal-request__block"
            >
                <div
                    @click="
                        $emit('close', false);
                        formSubmitted = false;
                    "
                    class="modal-request__close"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-x"
                    >
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                </div>
                <div class="modal-request__title">Заявка на парковку</div>
                <div v-if="formSubmitted" class="modal-request__title">Ваша заявка успешно отправлена</div>
                <div v-else class="modal-request__form">
                    <div class="form-group">
                        <div class="form-field form-field--wide">
                            <div class="form-field__label text_19">Ваше имя <span>*</span></div>
                            <div class="form-field__component">
                                <input class="form-group__input" type="text" required v-model="userName" />
                            </div>
                            <span v-if="errors.name" class="errors-text">{{ errors.name }}</span>
                        </div>
                        <div class="form-field form-field--wide">
                            <div class="form-field__label text_19">Ваш телефон <span>*</span></div>
                            <div class="form-field__component">
                                <input
                                    class="form-group__input"
                                    type="text"
                                    required
                                    v-model="userPhone"
                                    placeholder="+7"
                                />
                            </div>
                            <span v-if="errors.phone" class="errors-text">{{ errors.phone }}</span>
                        </div>
                    </div>
                    <div class="form-group contacts-form">
                        <div class="form-field form-field--wide">
                            <div class="form-field__label text_19">Тариф</div>
                            <div class="form-field__component">
                                <dropdown
                                    :key="JSON.stringify(selectedTariff)"
                                    class="my-dropdown-toggle"
                                    :options="getTariffs"
                                    :selected="selectedTariff"
                                    :placeholder="'Выберите'"
                                    :closeOnOutsideClick="true"
                                    @updateOption="
                                        selectedTariff = $event;
                                        selectedTerm = $event.offers[0];
                                    "
                                >
                                </dropdown>
                            </div>
                        </div>
                        <div class="form-field form-field--wide term-form">
                            <div class="form-field__label text_19">Срок</div>
                            <div class="form-field__component">
                                <dropdown
                                    :key="JSON.stringify(selectedTariff)"
                                    class="my-dropdown-toggle"
                                    :options="getTermTariff"
                                    :selected="selectedTerm"
                                    :placeholder="'Выберите'"
                                    :closeOnOutsideClick="true"
                                    @updateOption="selectedTerm = $event"
                                >
                                </dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="modal-request__price">Итого: {{ getPrice.price.toLocaleString() }}руб.</div>
                    <div class="form-group">
                        <label class="flex-c form-field__checkbox">
                            <input type="checkbox" name="agree" v-model="userAgree" />
                            <span>
                                <span>Даю согласие на</span>
                                <a href="https://parking.arlan-auto.ru/#/dokumenty/soglashenie" target="_blank"
                                    >обработку персональных данных</a
                                >
                            </span>
                        </label>
                    </div>
                    <div class="form-group form-group--lg-offset">
                        <div class="form-field form-field--centered">
                            <button class="button button--big" @click="submit()" :disabled="buttonSubmitActive">
                                Отправить заявку
                                <div class="loader" v-if="requestStatus === 'loading'"></div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import dropdown from "vue-dropdowns";
export default {
    name: "Modal",
    components: {
        dropdown,
    },
    data() {
        return {
            formSubmitted: false,
            userName: "",
            userPhone: "",
            userTariff: "",
            userPrice: "1000",
            userAgree: false,
            tariff: this.tariffs,
            selectedTariff: null,
            selectedTerm: null,
            tariffs: null,
            requestStatus: "",
            errors: {
                name: null,
                phone: null,
            },
        };
    },
    props: {
        openModal: {
            type: Boolean,
            default: false,
        },
    },
    async created() {
        const url = await this.$axios.get("/api/v1/parking/tariffs");
        url.data.data.forEach((el) => {
            el.price = el.offers[0].price;
            el.currentTab = el.offers[0];
        });
        this.tariffs = url.data.data;
        this.selectedTariff = this.getTariffs[0];
        this.selectedTerm = this.getTermTariff[0];
    },
    computed: {
        getTariffs() {
            const array = [];
            if (this.tariffs && this.tariffs.length) {
                this.tariffs.forEach((el) => array.push(el));
            }
            return array;
        },
        getTermTariff() {
            return this.tariffs.find((el) => el.name === this.selectedTariff.name).offers;
        },
        getPrice() {
            let price = null;
            price = this.tariffs
                .find((el) => el.name === this.selectedTariff.name)
                .offers.find((el) => el.name === this.selectedTerm.name);
            return price;
        },
        buttonSubmitActive() {
            if (this.userPhone && this.userName && this.userAgree) {
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        submit() {
            if (!this.userName || !this.userPhone) {
                this.showError("name");
                return false;
            }
            this.requestStatus = "loading";
            this.$axios
                .get("/api/v1/csrf_token")
                .then(() => {
                    this.$axios
                        .post("/api/v1/amo/create/lead/parking/site-lead", {
                            name: this.userName,
                            phone: this.userPhone,
                            price: Number(this.getPrice.price),
                            agree: this.userAgree,
                            tariff: this.selectedTariff.name + " " + this.selectedTerm.name,
                        })
                        .then(() => {
                            //console.log(response);
                            this.requestStatus = "";
                            this.errors.email = null;
                            this.errors.phone = null;
                            this.userName = "";
                            this.userPhone = "";
                            this.userAgree = false;
                            this.formSubmitted = true;
                        })
                        .catch((e) => {
                            console.log(`e =>`, e);
                            this.requestStatus = "error";
                            let error = e.response.data.data;
                            if (error.email) {
                                this.errors.email = error.email[0];
                            }
                            if (error.phone) {
                                this.errors.phone = error.phone[0];
                            }
                        });
                })
                .catch((e) => {
                    console.log(`e =>`, e);
                    this.requestStatus = "error";
                });
        },
    },
};
</script>
<style lang="less">
@import url("../assets/less/vars.less");

.modal-request {
    background: rgba(255, 255, 255, 0.192);
    backdrop-filter: blur(10px);
    position: absolute;
    inset: 0 0 0 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    @media @sm {
        height: 100vh;
    }
    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 130%;
        margin-bottom: 40px;
        margin-top: 20px;
        text-align: center;
        @media @sm {
            font-size: 28px;
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
    &__block {
        background: #fff;
        height: max-content;
        border-radius: 16px;
        box-shadow: 0px 13px 49px 0px #8791ab80;
        padding: 40px;
        position: relative;
        @media @sm {
            padding: 15px;
            height: 100%;
            overflow-y: scroll;
        }
    }
    &__close {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 40px;
        height: 40px;
        svg {
            cursor: pointer;
        }
        @media @sm {
            top: 10px;
            right: 10px;
        }
    }
    &__price {
        font-weight: bold;
        font-size: 24px;
        display: flex;
        align-items: center;
        padding: 20px;
        background: #f7f7f9;
        border-radius: 12px;
        margin: 24px 0;
        justify-content: flex-end;
        @media @sm {
            font-size: 20px;
        }
    }
    button {
        @media @sm {
            display: block;
            margin: 0 auto;
        }
    }
}
</style>
