<template>
    <div class="open-block" :class="{ 'open-block--overflow': hideContent }" ref="block">
        <slot :toggle="toggle" :isOpen="isOpen" :stillVisible="stillVisible"></slot>
    </div>
</template>
<script>
// work only with this markup
// <OpenBlock>
//     <template slot-scope="{ toggle }">
//         <div class="open-block">
//             <div class="open-block__label" @click="toggle($event)">some label</div>
//             <div class="open-block__wrapper">
//                 <div class="open-block__content">some content</div>
//             </div>
//         </div>
//     </template>
// </OpenBlock>
export default {
    name: "OpenBlock",
    data() {
        return {
            isOpen: false,
            stillVisible: false,
            height: 0,
            maxHeight: 0,
            content: null,
            label: null,
            block: null,
            hideContent: true,
        };
    },
    props: {
        state: {
            default: 42,
        },
        heightMod: {
            default: 0,
        },
    },
    watch: {
        state() {
            this.toggle();
        },
    },
    methods: {
        toggle() {
            this.isOpen = this.state !== 42 ? this.state : !this.isOpen;
            if (this.isOpen) {
                this.stillVisible = true;
                setTimeout(() => {
                    this.hideContent = false;
                }, 300);
            } else {
                this.hideContent = true;
                setTimeout(() => {
                    this.stillVisible = false;
                }, 300);
            }
            this.height = this.isOpen ? this.maxHeight : 0;
            this.content.style.height = this.height + "px";
            this.isOpen ? this.block.classList.add("is_open") : this.block.classList.remove("is_open");
        },
    },
    mounted() {
        let block = this.$refs.block;
        let label = block.firstChild.firstChild;
        let content = label.nextElementSibling;

        this.block = block;
        this.label = label;
        this.content = content;
        this.maxHeight = content.firstChild.offsetHeight + this.heightMod;

        this.label.classList.add("open-block__toggler");
        this.content.classList.add("open-block__content");
        this.content.style.height = this.height;
        // Функция обратного вызова при срабатывании мутации

        // Начинаем наблюдение за настроенными изменениями целевого элемента

        if (this.state != 42) {
            this.isOpen = false;
            this.$emit("resetState");
        }
    },
};
</script>
<style lang="less">
.open-block {
    position: relative;
    &__wrapper {
        transition: height 0.3s ease;
        .open-block--overflow & {
            overflow: hidden;
        }
    }
    &__label {
        cursor: pointer;
    }
}
</style>
