<template>
    <div v-if="page">
        <div class="hero site-block">
            <div class="hero__content">
                <AddressLine :page="page"></AddressLine>
                <h1 class="text_76_h" v-html="page.banner_text"></h1>
                <div v-if="page.banner_badge" class="hero__tilt-tag">
                    {{ page.banner_badge }}
                </div>
            </div>
            <CroppedImg
                v-if="page.banner_image && page.banner_image.path"
                class="shadow no-margin-sm"
                :url="page.banner_image.path"
                :size="[1360, 720]"
            ></CroppedImg>
        </div>
        <button @click="$emit('modal', true)" class="button button--want-parking">
            {{ page.form_title }}
        </button>
        <div class="row">
            <div class="col lg-10 sm-12 col--centered">
                <div class="row site-block">
                    <div class="col lg-6 sm-12">
                        <div class="text_40_b">
                            {{ page.features_title }}
                        </div>
                    </div>
                    <div class="col lg-6 sm-12">
                        <ul class="bene-list text_21_m">
                            <li v-for="(item, index) in page.features_items" :key="index" class="flex">
                                <div class="bene-list__icon">
                                    <img :src="item.icon" alt="" />
                                </div>
                                <div>
                                    {{ item.text }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="auto-grid site-block">
                    <div class="row">
                        <div class="col lg-6 col--padding-right">
                            <div class="auto-grid__item">
                                <CroppedImg
                                    v-if="page.photos && page.photos[0]"
                                    :url="page.photos[0].path"
                                    :size="[1400, 1340]"
                                    mode="resize"
                                ></CroppedImg>
                                <AddressLine :page="page"></AddressLine>
                            </div>
                        </div>
                        <div class="col lg-6 col--padding-left">
                            <div class="auto-grid__item">
                                <CroppedImg
                                    v-if="page.photos && page.photos[1]"
                                    :url="page.photos[1].path"
                                    :size="[570, 320]"
                                ></CroppedImg>
                            </div>
                            <div class="auto-grid__item">
                                <CroppedImg
                                    v-if="page.photos && page.photos[2]"
                                    :url="page.photos[2].path"
                                    :size="[570, 320]"
                                ></CroppedImg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="site-block">
                    <div class="tariffs">
                        <div class="text_40_b tac tariffs__header">Тарифы</div>
                        <div v-if="tariffs" class="row no-wrap-sm">
                            <div v-for="(tariff, index) in tariffs" :key="index" class="col lg-4 sm-12">
                                <div
                                    class="tariffs__item"
                                    :class="{
                                        'vip-active': tariff.is_vip,
                                    }"
                                >
                                    <CroppedImg :url="tariff.image" :size="[600, 400]"></CroppedImg>
                                    <div class="tariffs__item-content">
                                        <div class="text_32_b tariffs__title">
                                            {{ tariff.name }}
                                        </div>
                                        <ul class="tariffs__item-list">
                                            <li
                                                v-for="(param, index) in tariff.options"
                                                :key="index"
                                                :class="{
                                                    active: param.checked == 1,
                                                }"
                                            >
                                                {{ param.name }}
                                            </li>
                                        </ul>
                                        <div class="tariffs__item-time">
                                            <div
                                                v-for="(tab, index) in tariff.offers"
                                                :key="index"
                                                :class="{
                                                    'active-time': tab.price === tariff.price,
                                                }"
                                                @click="
                                                    tariff.price = tab.price;
                                                    tariff.currentTab = tab;
                                                "
                                            >
                                                {{ tab.name }}
                                            </div>
                                        </div>
                                        <div class="tariffs__item-price text_32_b">
                                            {{ tariff.price.toLocaleString() }}
                                            P
                                        </div>
                                        <div class="tilt-tag" @click="setAndShowTariff(tariff)">
                                            Выбрать и оплатить тариф
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tac">
                        <div class="price">Цены действительны с 01.07.2022 по 31.12.2022</div>
                    </div>
                </div>
                <div class="auto-grid auto-grid--2 site-block">
                    <div class="md-hide">
                        <div class="auto-grid__text">
                            <div class="auto-grid__icon">
                                <svg
                                    width="48"
                                    height="49"
                                    viewBox="0 0 48 49"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M9 5.50001V24.5L21.5 24.5C23.1228 24.5 24.7018 25.0263 26 26C27.2982 26.9737 28.8772 27.5 30.5 27.5H43L37 18L43 8.50001H30.5C28.8772 8.50001 27.2982 7.97367 26 7.00001C24.7018 6.02634 23.1228 5.5 21.5 5.5L9 5.50001ZM9 26.5V44.5H13V26.5H9Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                            <div class="text_21_m">
                                {{ page.personal_place_title }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col lg-6 col--padding-right gap-sm">
                            <div class="auto-grid__item">
                                <CroppedImg
                                    v-if="page.personal_place_photos && page.personal_place_photos[0]"
                                    :url="page.personal_place_photos[0].path"
                                    :size="[570, 320]"
                                ></CroppedImg>
                            </div>
                            <div class="auto-grid__item">
                                <CroppedImg
                                    v-if="page.personal_place_photos && page.personal_place_photos[2]"
                                    :url="page.personal_place_photos[2].path"
                                    :size="[570, 320]"
                                ></CroppedImg>
                            </div>
                        </div>
                        <div class="col lg-6 col--padding-left gap-sm">
                            <div class="auto-grid__item">
                                <CroppedImg
                                    v-if="page.personal_place_photos && page.personal_place_photos[1]"
                                    :url="page.personal_place_photos[1].path"
                                    :size="[570, 320]"
                                ></CroppedImg>
                            </div>
                            <div class="auto-grid__item">
                                <CroppedImg
                                    v-if="page.personal_place_photos && page.personal_place_photos[3]"
                                    :url="page.personal_place_photos[3].path"
                                    :size="[570, 320]"
                                ></CroppedImg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="apply">
                    <div class="form">
                        <div class="form__header">
                            <div class="text_40_b">
                                {{ page.form_title }}
                            </div>
                            <AddressLine :page="page"></AddressLine>
                            <div class="form__subtitle text_21_m">
                                <!-- <span v-if="!sended">{{
                                    page.form_description
                                }}</span> -->
                            </div>
                        </div>

                        <transition name="fade" mode="out-in">
                            <div v-if="!sended" key="1" class="form__content">
                                <div class="form-group">
                                    <div class="form-field form-field--wide">
                                        <div class="form-field__label text_19">Ваше имя <span>*</span></div>
                                        <div class="form-field__component">
                                            <input class="form-group__input" type="text" required v-model="userName" />
                                        </div>
                                        <!-- <span class="error">{{
                                            errorMessages.name
                                        }}</span> -->
                                    </div>
                                </div>
                                <div class="form-group contacts-form">
                                    <div class="form-field form-field--wide">
                                        <div class="form-field__label text_19">
                                            Email
                                            <span>*</span>
                                        </div>
                                        <div class="form-field__component">
                                            <input
                                                class="form-group__input"
                                                type="email"
                                                required
                                                placeholder=""
                                                v-model="email"
                                            />
                                        </div>
                                        <span v-if="errors.email" class="errors-text">{{ errors.email }}</span>
                                    </div>
                                    <div class="form-field form-field--wide">
                                        <div class="form-field__label text_19">
                                            Номер телефона
                                            <span>*</span>
                                        </div>
                                        <div class="form-field__component">
                                            <input
                                                class="form-group__input"
                                                type="tel"
                                                required
                                                placeholder="+7"
                                                v-model="phone"
                                                maxlength="15"
                                            />
                                        </div>
                                        <span v-if="errors.phone" class="errors-text">{{ errors.phone }}</span>
                                    </div>
                                </div>
                                <div class="form-info text_16_m">
                                    Согласно ФЗ-54, мы отправим вам чек на указанный адрес электронной почты
                                </div>
                                <div class="form-group contacts-form form__no-wrap">
                                    <div class="form-field form-field--wide">
                                        <div class="form-field__label text_19">Марка авто <span>*</span></div>
                                        <div class="form-field__component">
                                            <dropdown
                                                :key="JSON.stringify(nameAuto)"
                                                class="my-dropdown-toggle"
                                                :options="arrayNameAuto"
                                                :selected="nameAuto"
                                                :placeholder="'Выберите'"
                                                :closeOnOutsideClick="true"
                                                @updateOption="getModel($event)"
                                            >
                                            </dropdown>
                                        </div>
                                    </div>
                                    <div class="form-field form-field--wide term-form">
                                        <div class="form-field__label text_19">Модель <span>*</span></div>
                                        <div class="form-field__component">
                                            <dropdown
                                                :key="JSON.stringify(modelAuto)"
                                                class="my-dropdown-toggle"
                                                :options="arrayModelAuto"
                                                :selected="modelAuto"
                                                :placeholder="'Выберите'"
                                                :closeOnOutsideClick="true"
                                                @updateOption="modelAuto = $event"
                                            >
                                            </dropdown>
                                        </div>
                                    </div>
                                    <div class="form-field form-field--wide form-gosnomer">
                                        <div class="form-field__label text_19">Гос.номер <span>*</span></div>
                                        <div class="form-field__component">
                                            <input
                                                class="form-group__input btn-group"
                                                type="text"
                                                required
                                                placeholder="A 000 AA 000"
                                                v-model="gosNomer"
                                            />
                                        </div>
                                        <!-- <span class="error">{{
                                            errorMessages.name
                                        }}</span> -->
                                    </div>
                                </div>
                                <div class="form-group contacts-form">
                                    <div class="form-field form-field--wide">
                                        <div class="form-field__label text_19">Тариф</div>
                                        <div class="form-field__component">
                                            <dropdown
                                                :key="JSON.stringify(selectedTariff)"
                                                class="my-dropdown-toggle"
                                                :options="getTariffs"
                                                :selected="selectedTariff"
                                                :placeholder="'Выберите'"
                                                :closeOnOutsideClick="true"
                                                @updateOption="
                                                    selectedTariff = $event;
                                                    selectedTerm = $event.offers[0];
                                                "
                                            >
                                            </dropdown>
                                        </div>
                                    </div>
                                    <div class="form-field form-field--wide term-form">
                                        <div class="form-field__label text_19">Срок</div>
                                        <div class="form-field__component">
                                            <dropdown
                                                :key="JSON.stringify(selectedTariff)"
                                                class="my-dropdown-toggle"
                                                :options="getTermTariff"
                                                :selected="selectedTerm"
                                                :placeholder="'Выберите'"
                                                :closeOnOutsideClick="true"
                                                @updateOption="selectedTerm = $event"
                                            >
                                            </dropdown>
                                        </div>
                                    </div>
                                </div>
                                <div class="total-price">
                                    <div class="total-price__text text_16_m">
                                        Выделенное место место стандартного размера.
                                    </div>
                                    <div class="total-price__number text_24_b">
                                        {{ getPrice.price.toLocaleString() }} P
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="flex-c form-field__checkbox">
                                        <input type="checkbox" name="agree" v-model="agree" />
                                        <span>
                                            <span>Даю согласие на</span>
                                            <a
                                                href="https://parking.arlan-auto.ru/#/dokumenty/soglashenie"
                                                target="_blank"
                                                >обработку персональных данных</a
                                            >
                                        </span>
                                    </label>
                                    <div class="error" v-if="errorMessages.agree">
                                        {{ errorMessages.agree[0] }}
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="flex-c form-field__checkbox">
                                        <input type="checkbox" name="agreeAdvertising" v-model="agreeAdvertising" />
                                        <span>
                                            <span>Даю согласие на получение рекламы</span>
                                        </span>
                                    </label>
                                    <div class="error" v-if="errorMessages.agree">
                                        {{ errorMessages.agree[0] }}
                                    </div>
                                </div>

                                <div class="form-group form-group--lg-offset">
                                    <div class="form-field form-field--centered">
                                        <button
                                            class="button button--big"
                                            @click="submit()"
                                            :disabled="buttonSubmitActive"
                                        >
                                            Оплатить
                                            <div class="loader" v-if="requestStatus === 'loading'"></div>
                                        </button>
                                    </div>
                                </div>
                                <img src="../assets/img/logo3h.png" class="form-visa__img" alt="" />
                                <div class="text_24_b tac submit-error" v-if="requestStatus === 'error'">
                                    Произошла ошибка отправки формы,
                                    <br />позвоните по номеру телефона
                                    <a href="tel:+78482713013">8 (8482) 713 - 013</a>
                                </div>
                            </div>
                            <div class="form__content" key="2" v-else>
                                <div class="text_24_b tac">
                                    Заявка отправлена, менеджер свяжется с вами в ближайшее время
                                </div>
                            </div>
                        </transition>
                        <div class="form__footer">
                            <div class="form__footer-icon"></div>
                            <div class="form__footer-text">
                                После оплаты обязательно дождитесь звонка оператора для подтверждения транзакции. После
                                этого вы сможете заехать на паркинг.
                            </div>
                        </div>
                    </div>
                    <form ref="formOrder" method="POST" action="https://parking-arlan-auto.server.paykeeper.ru/create/">
                        <input type="hidden" name="sum" :value="formSum" />
                        <input type="hidden" name="orderid" v-model="formOrderId" />
                        <input type="hidden" name="service_name" v-model="formService" />
                        <input type="hidden" name="client_phone" v-model="formClientPhone" />
                        <input type="hidden" name="clientid" v-model="formClientName" />
                        <input type="hidden" name="client_email" v-model="formClientEmail" />
                        <input type="hidden" value="Перейти к оплате" />
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import CroppedImg from "@/components/CroppedImg";
import AddressLine from "@/components/AddressLine";
import dropdown from "vue-dropdowns";
axios.defaults.withCredentials = true;
export default {
    name: "MainPage",
    components: {
        CroppedImg,
        AddressLine,
        dropdown,
    },
    data() {
        return {
            page: null,
            requestStatus: "",
            sended: false,
            errorMessages: {
                name: "",
            },
            userName: "",
            gosNomer: "",
            agree: false,
            agreeAdvertising: true,
            phone: "",
            email: "",
            phoneTranslations: {
                countrySelectorLabel: "Код",
                countrySelectorError: "Ошибка",
                phoneNumberLabel: "",
                example: "Например:",
            },
            tariff: this.tariffs,
            selectedTariff: null,
            selectedTerm: null,
            tariffs: null,
            nameAuto: "",
            modelAuto: "",
            arrayNameAuto: null,
            arrayModelAuto: null,
            formSum: null,
            formOrderId: null,
            formService: "",
            formClientPhone: "",
            formClientName: null,
            formClientEmail: null,
            errors: {
                name: null,
                email: null,
                phone: null,
            },
        };
    },
    async created() {
        const url = await this.$axios.get("/api/v1/parking/tariffs");
        url.data.data.forEach((el) => {
            el.price = el.offers[0].price;
            el.currentTab = el.offers[0];
        });
        this.tariffs = url.data.data;
        this.selectedTariff = this.getTariffs[0];
        this.selectedTerm = this.getTermTariff[0];
        const arrayAuto = await this.$axios.get("/api/v1/carbase/marks ");
        this.arrayNameAuto = arrayAuto.data.data;
    },
    computed: {
        getTariffs() {
            const array = [];
            if (this.tariffs && this.tariffs.length) {
                this.tariffs.forEach((el) => array.push(el));
            }
            return array;
        },
        getTermTariff() {
            return this.tariffs.find((el) => el.name === this.selectedTariff.name).offers;
        },
        getPrice() {
            let price = null;
            price = this.tariffs
                .find((el) => el.name === this.selectedTariff.name)
                .offers.find((el) => el.name === this.selectedTerm.name);
            return price;
        },
        buttonSubmitActive() {
            if (this.phone && this.gosNomer && this.nameAuto && this.modelAuto && this.userName && this.agree) {
                return false;
            } else {
                return true;
            }
        },
    },
    methods: {
        setAndShowTariff(el) {
            this.selectedTariff = el;
            this.selectedTerm = el.currentTab;
            this.showForm();
        },
        showForm() {
            document.querySelector(".apply").scrollIntoView();
        },
        setTariff(el) {
            this.selectedTariff = el.name;
        },
        showError(field) {
            let message = "заполните это поле";
            if (field == "name") {
                message = "заполните это поле";
            }
            this.errorMessages[field] = message;
        },
        async getModel(event) {
            this.nameAuto = event;
            const request = await this.$axios.get("/api/v1/carbase/models", {
                params: { mark: event.id },
            });
            this.arrayModelAuto = request.data.data;
        },
        submit() {
            if (!this.userName || !this.phone || !this.gosNomer || !this.nameAuto || !this.modelAuto || !this.email) {
                this.showError("name");
                return false;
            }

            this.requestStatus = "loading";
            this.$axios
                .get("/api/v1/csrf_token")
                .then(() => {
                    this.$axios
                        .post("/api/v1/parking/orders", {
                            name: this.userName,
                            phone: this.phone,
                            email: this.email,
                            agreement: this.agree,
                            offer_id: this.selectedTerm.id,
                            auto_mark: this.nameAuto.name,
                            auto_model: this.modelAuto.name,
                            auto_number: this.gosNomer,
                            ads_agreement: this.agreeAdvertising,
                        })
                        .then((response) => {
                            // this.sended = true;
                            this.requestStatus = "";
                            const dataResponse = response.data.data;
                            this.formSum = dataResponse.amount;
                            this.formOrderId = dataResponse.id;
                            this.formService = dataResponse.tariff.name + " " + dataResponse.offer.name;
                            this.formClientPhone = dataResponse.formatted_phone;
                            this.formClientName = dataResponse.name;
                            this.formClientEmail = dataResponse.email;
                            this.errors.email = null;
                            this.errors.phone = null;
                            this.$nextTick(() => {
                                this.$refs.formOrder.submit();
                            });
                        })
                        .catch((e) => {
                            console.log(`e =>`, e);
                            this.requestStatus = "error";
                            let error = e.response.data.data;
                            if (error.email) {
                                this.errors.email = error.email[0];
                            }
                            if (error.phone) {
                                this.errors.phone = error.phone[0];
                            }
                        });
                })
                .catch((e) => {
                    console.log(`e =>`, e);
                    this.requestStatus = "error";
                });
        },
    },
    mounted() {
        //const url = "/mock.json";
        this.$axios.get("/api/v1/parking/landing").then(({ data }) => {
            const res = data.data;
            this.page = res;
        });
    },
};
</script>
<style lang=""></style>
