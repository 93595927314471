<template>
    <div class="page-success">
        <div class="page-success__block">
            <div class="page-success__image">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#ffffff"
                    viewBox="0 0 50 50"
                    width="100px"
                    height="100px"
                >
                    <path
                        d="M 41.9375 8.625 C 41.273438 8.648438 40.664063 9 40.3125 9.5625 L 21.5 38.34375 L 9.3125 27.8125 C 8.789063 27.269531 8.003906 27.066406 7.28125 27.292969 C 6.5625 27.515625 6.027344 28.125 5.902344 28.867188 C 5.777344 29.613281 6.078125 30.363281 6.6875 30.8125 L 20.625 42.875 C 21.0625 43.246094 21.640625 43.410156 22.207031 43.328125 C 22.777344 43.242188 23.28125 42.917969 23.59375 42.4375 L 43.6875 11.75 C 44.117188 11.121094 44.152344 10.308594 43.78125 9.644531 C 43.410156 8.984375 42.695313 8.589844 41.9375 8.625 Z"
                    />
                </svg>
            </div>
            <div class="page-success__text">Success</div>
        </div>
    </div>
</template>
<script>
export default {
    name: "MainPage",
    data() {
        return {};
    },
};
</script>
<style lang="less">
.page-success {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    &__block {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__image {
        width: 150px;
        height: 150px;
        background: green;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__text {
        font-weight: 700;
        font-size: 36px;
        margin-top: 20px;
    }
}
</style>
