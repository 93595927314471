<template>
    <a
        :href="`https://yandex.ru/maps/org/arlan_avto/1072222812/?ll=${page.address_lat}%2C${page.address_lon}&z=14`"
        target="_blank"
        class="text_24_b flex-c"
    >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5 2C7.91593 2 5 5.11865 5 8.9519C5 13.7699 11.5064 22 11.5064 22C11.5064 22 18 13.533 18 8.9519C18 5.11865 15.0842 2 11.5 2ZM13.4612 10.9874C12.9204 11.5657 12.2103 11.8549 11.5 11.8549C10.7899 11.8549 10.0795 11.5657 9.53893 10.9874C8.4575 9.83093 8.4575 7.9491 9.53893 6.79248C10.0626 6.23218 10.7591 5.92358 11.5 5.92358C12.2409 5.92358 12.9373 6.2323 13.4612 6.79248C14.5426 7.9491 14.5426 9.83093 13.4612 10.9874Z"
                fill="currentColor"
            />
        </svg>
        {{ page.address_label }}
    </a>
</template>
<script>
export default {
    name: "AddressLine",
    props: {
        page: {
            type: Object,
            default: null,
        },
    },
};
</script>
<style scoped lang="less">
@import url("../assets/less/vars.less");
a {
    text-decoration: none;
    color: currentColor;
    gap: 8px;
    justify-content: center;
    &:hover {
        color: @red;
    }
    svg {
        flex-shrink: 0;
    }
}
</style>
