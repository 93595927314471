import Vue from "vue";
import App from "./App.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import router from "./router/router";
import axios from "axios";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import vClickOutside from "v-click-outside";

Vue.config.productionTip = false;
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.prototype.$axios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
});
Vue.use(vClickOutside);
new Vue({
    render: (h) => h(App),
    router,
}).$mount("#app");

// на локалке https://backend-arlan.test
// на бою https://api.arlan-auto.ru
