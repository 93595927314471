<template>
    <div class="cropped-img">
        <img @load="$emit('load')" :src="src" :srcset="src_2x" alt="" />
    </div>
</template>
<script>
export default {
    name: "CroppedImg",
    props: {
        mode: {
            type: String,
            default: "crop",
        },
        size: {
            type: Array,
            default() {
                return [100, 100];
            },
        },
        url: {
            type: String,
            default: "",
        },
    },
    computed: {
        src() {
            // if (process.env.NODE_ENV !== "production") {
            //     return `https://picsum.photos/${this.size.join("/")}`;
            // }
            return `https://cdn.arlan-auto.ru/img/${this.mode}/${this.size.join(
                "x"
            )}/${this.url.replace("://", "/")}`;
        },
        src_2x() {
            // if (process.env.NODE_ENV !== "production") {
            //     return `https://picsum.photos/${this.size_2x.join("/")}`;
            // }
            return `https://cdn.arlan-auto.ru/img/${
                this.mode
            }/${this.size_2x.join("x")}/${this.url.replace("://", "/")}`;
        },
        size_2x() {
            return this.size.map((el) => {
                if (typeof el == Number) {
                    return el * 2;
                }
                return el;
            });
        },
    },
};
</script>
<style lang="less">
.cropped-img {
    img {
        object-fit: cover;
    }
}
</style>
